<template>
  <div>
    <div class="user">
      <a-button type="primary" @click="getAccount">获取登陆账号和密码</a-button>
      <p style="margin-left: 20px;color:red;">注意：登陆时请选择企业账户登陆</p>
    </div>
    <!-- 
    <iframe id="contents" src="https://report.seedsufe.com/index" frameborder="0" style="width:100%;height:800px"></iframe>
    -->
  

    <a-modal v-model="visible" title="获取登陆账号和密码" @ok="handleOk">
      <p>用户名：yantietang001</p>
      <p>密码：b12646c393</p>

      <p>访问链接：<a href="https://report.seedsufe.com/index" target="_blank">https://report.seedsufe.com/index</a></p>

      <span slot="footer" class="dialog-footer">
        <a-button @click="visible = false">关闭</a-button>
      </span>
    </a-modal>
    <div @click="getAccount"><img src="@/assets/market.jpg" alt=""  style="width: 100%; height: 100%;" /></div>
  </div>
</template>

<script>
import { addResourcesUse, getResourcesID } from "@/httpsAPI/resources/common";
export default {
  data () {
    return {
      acctName: '',
      acctPwd: '',
      visible: false,
    };
  },
  created () {
    addResourcesUse({ type: 3, content: '市场报告统计' }).then(res => { })
  },
  methods: {
    getAccount () {
      getResourcesID().then(res => {
        this.acctName = res.data.username
        this.acctPwd = res.data.password
        this.visible = true;
      })
    },
    handleOk (e) {
      this.visible = false;
    }
  }
}
</script>

<style lang="less" scoped>
.user {
  height: 45px;
  p {
    display: inline-block;
  }
}
</style>>

